.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: blue;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-grid-item:not(.react-grid-placeholder) {
  background: whitesmoke;
}

.window-header {
  background-color: lightblue;
  display: flex;
  justify-content: space-between;
  height: 20px;
}

.app-logo {
  display: flex;
}

.app-logo-icon {
  height: 20px;
}

.app-name {
  font-size: 14px;
  padding-left: 8px;
}

.actions-container {
  display: flex;
}

.window-button-style {
  background-color: transparent;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  transition: background-color 150ms ease;
}

.window-controls-icon {
  fill: #958fb2;
  pointer-events: none;
  user-select: none;
  width: 20px;
  height: 20px;
  transition: fill 150ms ease;
}

.window-controls-icon:hover {
  background-color: rgb(77, 46, 46);
}

.window-controls-icon {
  fill: #f4f2ff;
}

.settings-window:hover {
  background-color: orange;
}

.close-window:hover {
  background-color: #e6004c;
}

.close-window::focus {
  outline: none;
}

/* assign dashboard */
.marginTop {
  margin-top: 40px;
}
.bg-layout {
  background-color: #e3e2e7;
}

#description1 .ql-container {
  height: 150px;
}

/* tank css */
#tank-container {
  height: 100%;
  overflow: hidden;
  width: 100%;
  border: 2px solid #000;
  border-top: none;
  border-radius: 0 0 10px 10px;
  position: relative;
}
.tank-top-round {
  height: 40px;
  border: 2px solid #000;
  border-radius: 50%;
  margin-bottom: -18px;
  position: relative;
  z-index: 1000;
}
.tank-middle-round {
  height: 40px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-bottom: -2px;
  position: absolute;
  width: 100%;
  z-index: 1000;
  background-color: #f4b183;
}
.baseLine {
  border: 1px dashed red;
  position: absolute;
  width: 100%;
  z-index: 1001;
}

.fullscreen-enabled {
  background-color: #fff !important;
  overflow-y: scroll;
}

.alarm-graph > div {
  height: 250px !important;
}
.select__indicator svg,
.ant-picker-suffix {
  color: #727070 !important;
}
.select__placeholder {
  color: #514f4f !important;
}

.ant-picker-range {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ant-picker-range .ant-picker-input {
  width: auto;
}
/* .ant-tabs-tab-active {
  color: #000 !important;
} */

.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-weight: 500;
  font-size: 16px;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #157347 !important;
}
/* .substation-overview-wrapper
  :where(.css-dev-only-do-not-override-1i9hnpv).ant-select
  .ant-select-arrow {
    margin-top: -6px;
  vertical-align: 0.4rem !important;
} */
/* .substation-overview-wrapper */
:where(.css-dev-only-do-not-override-1i9hnpv).ant-select .ant-select-clear {
  margin-top: -9px;
  /* vertical-align: 0.4rem !important; */
}
