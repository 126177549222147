.leaflet-container {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 50;
}

a {
  color: #000000 !important;
  text-decoration: none !important;
}

.btn {
  color: #ffffff !important;
}

.az-content-wrapper {
  background-size: cover;
}

.az-signin-header label {
  color: #000000;
}

.az-card-signin {
  background-color: rgb(255 255 255 / 80%) !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.swal2-styled.swal2-confirm {
  background-color: #157347 !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-styled.swal2-cancel:focus {
  box-shadow: none !important;
}

.az-header-center {
  margin: 0px !important;
}

.dropdown-menu.show {
  min-width: 220px !important;
}

.dropdown-item {
  padding: 3px 13px !important;
  font-size: 15px;
}

.az-sidebar-body .nav-item {
  cursor: pointer;
}

.description p {
  margin: 0px !important;
}

.rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  font-size: 15px !important;
}

.rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  font-size: 14px !important;
}

.addDeviceBtn {
  margin-left: 491px;
}

.checkbox {
  margin-bottom: 15px;
  margin-left: 20px;
}

/* .footer {
  margin-top: 200px;
} */

/* body{
    overflow-x: hidden;
  } */

.clickbtn {
  cursor: pointer;
}

::placeholder,
select {
  text-transform: capitalize !important;
}

:where(.css-dev-only-do-not-override-1i9hnpv).ant-picker-dropdown {
  z-index: 99999999 !important;
}

@font-face {
  font-family: text-security-disc;
  src: url("./assets/fonts/text-security-disc.woff");
}

input.password:not(.show-password) {
  font-family: text-security-disc;
}

/* input.password:not(.show-password) {
  -webkit-text-security: none;
}
input.password:not(.show-password) {
  -webkit-text-security: circle;
}
input.password:not(.show-password) {
  -webkit-text-security: square;
}
input.password:not(.show-password) {
  -webkit-text-security: disc;
} */

.react-grid-item.my-4.border.cssTransforms.react-resizable-hide.react-resizable {
  background-color: #ffffff;
}
.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}
.ant-select .ant-select-selection-placeholder,
.ant-picker-input input::placeholder {
  color: #212529 !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.time-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background: #ffffff;
}
/* .time-dropdown,
.time-dropdown > ul {
  background: #2c2c2c !important;
}

.time-input > span,
.time-input > span > input,
.time-input > span > input::placeholder,
.time-input > .ant-picker,
.time-input > .ant-picker > .ant-picker-input > input,
.time-input > .ant-picker > .ant-picker-input > input::placeholder,
.time-input > .ant-picker-input > input:disabled,
.ant-picker-disabled {
  background: #000000 !important;
  color: lightgray !important;
  border: gray;
} */
.quick-time-option {
  max-height: 300px;
  overflow-y: scroll;
}
.quick-time-option::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: lightgray;
}
.quick-time-option::-webkit-scrollbar {
  width: 10px;
  background-color: lightgray;
}
.quick-time-option::-webkit-scrollbar-thumb {
  background-color: gray;
  border: 2px solid lightgray;
}

.ant-popconfirm-message {
  display: flex;
  align-items: center !important;
}

.ant-popconfirm
  .ant-popconfirm-message
  > .ant-popconfirm-message-icon
  .anticon {
  padding-top: 0px;
  margin-top: 1px;
  display: block;
}

.client_view .react-resizable-handle {
  display: none;
}
.client_view .border {
  border: none !important;
}
.popconfirm_cancel_btn:hover {
  background: #6c757d !important;
}
.popconfirm_warning_btn {
  color: #fff !important;
}
.popconfirm_warning_btn:hover {
  background: #d39e00 !important;
}
.alarm-history-table{
  min-width: 1024px;
  /* overflow-x: scroll; */
}
.c-table-first-column {
  display: flex;
  font-weight: 500;
}
.c-table-first-column > .header-one {
  width: 200px;
  text-align: center;
  border: 1px solid lightgray;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  /* padding: 3px 0; */
}
.c-table-first-column > .header-two {
  width: 100px;
  text-align: center;
  border: 1px solid lightgray;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ced7e0;
}
.c-table-first-column > .header-three {
  text-align: center;
  /* width: calc(100%-350px); */
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
}
.c-table-second-column {
  display: flex;
  align-items: center;
}
.c-table-second-column > .header-one {
  width: 200px;
  text-align: center;
  border: 1px solid lightgray;
  border-top: 0;
  height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-table-second-column > .header-two {
  width: 150px;
  text-align: center;
  border-bottom: 1px solid lightgray;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-table-second-column > .panel-header {
  width: 100px;
  text-align: center;
  border: 1px solid lightgray;
  border-top: 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-table-row {
  display: flex;
  align-items: center;
}
.c-table-row > .header-one {
  width: 200px;
  text-align: center;
  border: 1px solid lightgray;
  border-top: 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-table-row > .header-two {
  width: 150px;
  text-align: center;
  border-bottom: 1px solid lightgray;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-table-row > .panel-header {
  width: 100px;
  text-align: center;
  border: 1px solid lightgray;
  border-top: 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel-overview-table-head{
  width: 150px;
  text-align: center;
  font-weight: 500;
  background: #f2f2f2;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgray;
}
.panel-overview-table-row{
  width: 150px;
  text-align: center;
  display: flex;
  border: 1px solid lightgray;
}

.critical_alarm {
  -webkit-animation: blinking_critical 1s infinite; 
  -moz-animation: blinking_critical 1s infinite; 
  -o-animation: blinking_critical 1s infinite;  
  animation: blinking_critical 1s infinite; 
}
.high_alarm {
  -webkit-animation: blinking_high 1s infinite; 
  -moz-animation: blinking_high 1s infinite;  
  -o-animation: blinking_high 1s infinite;  
  animation: blinking_high 1s infinite;  
}
.warning_alarm{
  -webkit-animation: blinking_warning 1s infinite; 
  -moz-animation: blinking_warning 1s infinite;  
  -o-animation: blinking_warning 1s infinite;  
  animation: blinking_warning 1s infinite;  
}

@keyframes blinking_critical {
  0%, 49% {
    background-color: #f6ac078c;
    color: #ffffff;
  }
  50%, 100% {
    background-color: #ffffff;
    color: #f6ac078c;
  }
}

@keyframes blinking_high {
  0%, 49% {
    background-color: #eb0202b8;
    color: #ffffff;
  }
  50%, 100% {
    background-color: #ffffff;
    color: #eb0202b8;
  }
}
@keyframes blinking_warning {
  0%, 49% {
    background-color: #f6f601a3;
    color: #ffffff;
  }
  50%, 100% {
    background-color: #ffffff;
    color: #f6f601a3;
  }
}




